import React, { Component } from "react"
import Layout from "../components/layout"
import ArticleItem from "../components/articleItem"
import { graphql } from 'gatsby'

const CategoryTemplate = props => {
  return (
    <Layout>
      <h2>Category: {props.pageContext.name}</h2>
      <div className="listing">
        {props.data.allWordpressPost && props.data.allWordpressPost.edges.map(
          (item, index) => {
            return (
              <ArticleItem
                key={item.node.id}
                slug={item.node.slug}
                title={item.node.title}
                fluid={
                  item.node.featured_media.localFile ? item.node.featured_media.localFile.childImageSharp.fluid : ''
                }
                content={item.node.excerpt}
                format={item.node.format}
                categories={item.node.categories}
              />
            )
          }
        )}
      </div>
    </Layout>
  )
}

export default CategoryTemplate

export const catQuery = graphql`
  query CategoryPage($slug: String!) {
    allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          id
          slug
          status
          title
          excerpt
          template
          format
          content
          tags {
            id
            name
            slug
          }
          categories {
            id
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
